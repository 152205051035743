<script>
import { Circle8 } from "vue-loading-spinner";
import axios from "axios";
import Config from "../../../config.js";
import Swal from "sweetalert2";

export default {
  props: { isNewPassword: String, backLogin: Function },
  components: {
    Circle8,
  },
  data() {
    return {
      data_url:Config,
      email: "",
      loading: false,
      sended: false,
    };
  },
  methods: {

    async tryToSendVal() {

      if(this.email == ""){
        alert("Email must be filled");
        return
      }

      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (!this.email.match(validRegex)) {
        alert("Invalid email address!");
        return;
      } 

      let formData = new URLSearchParams();

      if (this.email != "") {
        formData.append("email", this.email);
      }

       const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                  confirmButton: "btn btn-success",
                  cancelButton: "btn btn-danger ml-2",
                },
                buttonsStyling: false,
              });

       const swalWithBootstrapButtonsdanger = Swal.mixin({
                customClass: {
                  confirmButton: "btn btn-danger",
                  cancelButton: "btn btn-danger ml-2",
                },
                buttonsStyling: false,
              });

      this.sended = false;
      this.loading = true;
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.formResetPassword,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded", //"multipart/form-data"  "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.sended = true;

            

             swalWithBootstrapButtons.fire(
                    "Success !",
                    "New Password has sent To Your Email",
                    "success"
                  );

            this.email = "";
            this.backLogin();

          } else {

            swalWithBootstrapButtonsdanger.fire(
                    "Failed !",
                    res.data.message,
                    "danger"
                  );

            

          }
        })
        .catch((error) => {
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });

      this.loading = false;
    },
  },
};
</script>

<template>
  <div>
    <form class="form-horizontal" @submit.prevent="tryToSendVal">
      <div class="form-group auth-form-group-custom mb-4">
        <input
          type="text"
          name="text"
          class="input1"
          v-model="email"
          style="width: 100%"
          placeholder="Email"
        />
      </div>
      <div class="mt-4 text-center d-flex justify-content-center">
        <div v-if="loading" style="width: 40px; height: 40px">
          <Circle8></Circle8>
        </div>
        <button
          v-if="!loading"
          class="btn bw-md waves-effect waves-light mt-2 font-poppins button-login"
          type="submit"
          style="
            width: 100%;
            height: 50px;
            border-radius: 20px;
            font-weight: 700;
            font-size: 24;
            line-height: 36px;
            color: #ffffff;
            letter-spacing: 5px;
            background-color: #fb646b;
            box-shadow: 6px 6px 12px #ffffff, -6px -6px 12px #c5c5c5;
          "
        >
          Send Confirm
        </button>
      </div>
    </form>
  </div>
</template>
