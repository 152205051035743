<script>
// helper.js -> autfacks.js -> user.service -> fake-backend.js
import { required } from "vuelidate/lib/validators";

import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";
import { Circle8 } from "vue-loading-spinner";
import ForgotPassword from "./forgot-password.vue";

export default {
  components: {
    Circle8,
    ForgotPassword,
  },
  data() {
    return {
      errorLogin: false,
      errorLoginMsg: "Invalid User ID or Password",
      version: process.env.VUE_APP_VERSION,
      email: "",
      emailf: "",
      loading: false,
      isNewPassword: "N",
      isGoForget: false,
      password: "",
      password_blur: "",
      submitted: false,
      cssProps: {
        backgroundImage: `url(${require("@/assets/images/authentication-bg.jpg")})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "100vh",
        width: "100%",
      },
    };
  },
  computed: {
    v_password_computed: {
      get() {
        return this.password_blur;
      },
      set(val) {
        let hruf = "";
        let dt_blur = "";
        let data_real = this.password.slice(0, val.length);
        data_real = data_real.split("");
        for (let i = 0; i < val.length; i++) {
          hruf = val.charAt(i);
          if (hruf != "*") {
            data_real[i] = hruf;
          }
          dt_blur += "*";
        }
        this.password_blur = dt_blur;
        this.password = data_real.join("");
      },
    },
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required },
    password: { required },
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    goForget() {
      if (this.isGoForget) {
        this.isGoForget = false;
      } else {
        this.isGoForget = true;
      }
    },

    backLogin(){
      this.isGoForget = false;
    },

    async tryToLogIn() {
      let dis = this;
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          this.tryingToLogIn = true;
          // Reset the authError if it existed.
          this.authError = null;
          return (
            this.logIn({
              email: this.email,
              password: this.password,
            })
              // eslint-disable-next-line no-unused-vars
              .then((token) => {
                this.tryingToLogIn = false;
                this.isAuthError = false;
                // Redirect to the originally requested page, or to the home page
                this.$router.push(
                  this.$route.query.redirectFrom || { name: "home" }
                );
              })
              .catch((error) => {
                this.tryingToLogIn = false;
                this.authError = error ? error : "";
                this.isAuthError = true;
              })
          );
        } else {
          const { email, password } = this;
          if (email && password) {
            dis.loading = true;
            this.errorLogin = false;
            await this.login({ email, password }).then((data) => {
              if (data != "berhasil") {
                this.errorLogin = true;
                this.errorLoginMsg = data;
                dis.loading = false;
              }
            });
          }
        }
      }
    },
  },
};
</script>

<style>
.glass-ul {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.18);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.1px);
  -webkit-backdrop-filter: blur(5.1px);
}

.input1 {
  border: none;
  border-radius: 15px;
  padding: 15px;
  background-color: #e8e8e8;
  box-shadow: 6px 6px 12px #ffffff, -6px -6px 12px #c5c5c5;
  font-size: medium;
  font-weight: bold;
}

.input1:focus {
  outline-color: white;
  place-content: "Enter your message!";
}

.button-login:hover {
  border: 1px solid white;
}
</style>

<template>
  <div>
    <div class="p-0 position-relative" :style="cssProps">
      <div
        class="glass-ul pl-0 position-absolute col-xl-4 col-lg-6 col-md-6 col-sm-9 col-12"
        style="
          border-radius: 20px;
          background-color: #ffffffb2;
          top: 0;
          bottom: 0;
          height: 600px;
          left: 0;
          margin: auto;
          right: 0;
        "
      >
        <div class="row no-gutters">
          <div class="w-100">
            <div
              class="text-center px-2"
              style="margin-bottom: 20px; margin-top: 10px"
            >
              <a href="/" class="logo">
                <img
                  class="img-fluid"
                  src="@/assets/images/logok.png"
                  alt="logo"
                />
              </a>
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-7 col-md-8 col-sm-10">
                <div>
                  <div class="text-left">
                    <h4
                      class="font-size-26 font-poppins"
                      style="font-weight: 600"
                    >
                      Welcome to,
                    </h4>
                    <span
                      class="mt-2 font-poppins font-weight-bold"
                      style="color: #779341; display: block; font-size: 31px"
                      >Karya Share Cloud</span
                    >
                  </div>

                  <div style="min-height: 40px">
                    <b-alert
                      variant="danger"
                      class="mb-0"
                      v-if="errorLogin"
                      show
                      >{{ errorLoginMsg }}</b-alert
                    >
                  </div>

                  <div class="p-2" style="color: black !important">
                    <form
                      class="form-horizontal"
                      @submit.prevent="tryToLogIn"
                      v-if="!isGoForget"
                    >
                      <div class="form-group auth-form-group-custom mb-4">
                        <input
                          type="text"
                          name="text"
                          class="input1"
                          v-model="email"
                          style="width: 100%"
                          placeholder="Email"
                        />
                      </div>
                      <div class="form-group auth-form-group-custom mb-4">
                        <input
                          type="password"
                          v-model="v_password_computed"
                          name="text"
                          class="input1"
                          style="width: 100%"
                          placeholder="Password"
                        />
                      </div>
                      <div
                        class="mt-4 text-center d-flex justify-content-center"
                      >
                        <div v-if="loading" style="width: 40px; height: 40px">
                          <Circle8></Circle8>
                        </div>
                        <button
                          v-if="!loading"
                          class="btn bw-md waves-effect waves-light mt-2 font-poppins button-login"
                          type="submit"
                          style="
                            width: 100%;
                            height: 50px;
                            border-radius: 20px;
                            font-weight: 700;
                            font-size: 24;
                            line-height: 36px;
                            color: #ffffff;
                            letter-spacing: 5px;
                            background-color: #fb646b;
                            box-shadow: 6px 6px 12px #ffffff,
                              -6px -6px 12px #c5c5c5;
                          "
                        >
                          LogIn
                        </button>
                      </div>
                    </form>

                    <div
                      class="mt-4 text-center"
                      @click="goForget"
                      v-show="!isGoForget"
                      style="cursor: pointer"
                    >
                      <i class="mdi mdi-lock mr-1"></i> Forgot your password ?
                    </div>

                    <forgot-password
                      :isNewPassword="isNewPassword"
                      :backLogin="backLogin"
                      v-if="isGoForget"
                    ></forgot-password>

                    <div
                      class="mt-4 text-center"
                      @click="goForget"
                      v-show="isGoForget"
                      style="cursor: pointer"
                    >
                      <i class="mdi mdi-login mr-1"></i> Back To Login ?
                    </div>
                  </div>

                  <div class="mt-2 text-center">
                    <p>
                      Supported by <i class="mdi mdi-heart text-danger"></i>
                      <img
                        src="@/assets/images/logo-dark1.png"
                        height="20"
                        alt="logo"
                        class="mb-2 ml-1"
                      /> <br/> <span style="font-size:12px;">{{ version }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="@/assets/images/paksatpam.png"
            class="d-none d-sm-block"
            style="position: absolute; left: -250px; bottom: 10px; width: 352px"
          />
        </div>
      </div>
    </div>
  </div>
</template>
